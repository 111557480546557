<template>
  <v-card style="padding-bottom: 100px !important; padding-top: 50px !important;">
    <v-app-bar color="white"
               light
               fixed
               elevation="0"
               dense
    >
      <v-btn
          light
          icon
          @click="$emit('close')"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-text-field
          v-model="search"
          placeholder="Поиск команды"
          hide-details
          clearable
          single-line
      ></v-text-field>
    </v-app-bar>
    <div  style="margin-top: 40px" v-for="team in teams" :key="team.id" class="team">
      <h2>
        {{ team.name }}
      </h2>
      <div :id="'team_'+team.id" style="top: 0; left: 0"></div>
      <div class="team-description">
        <div v-if="team.slot">
          <v-chip class="mt-2 mr-2" color="green" dark>
            {{ $moment.utc(team.slot.start_at).local().format('dd DD.MM HH:mm') }}
          </v-chip>
          <v-chip v-if="team.points > 0" class="mt-2 mr-2">
            {{ team.points }} б
          </v-chip>

        </div>

        {{ team.description }}
        <div>
          <h5>Участники</h5>
          <UserLinkComponent v-for="member in team.members" :key="member.id" :user="member"
                             class="mt-3 d-block d-sm-inline-block mr-0 mr-sm-3"/>
        </div>

        <div v-if="team.repo_link">
          <a :href="team.repo_link" target="_blank">Репозиторий</a>
        </div>
        <div v-if="team.prototype_link">
          <a :href="team.prototype_link" target="_blank">Демо</a>
        </div>
        <div v-if="team.project_id > 0">
          <a :href="'https://student.surgu.ru/#/projects/'+team.project_id" target="_blank">Ссылка на проект</a>
        </div>
        <h5 class="mt-4">Файлы</h5>
        <div v-if="!team.files.length" class="text-center" style="font-size: 12px">Нет файлов</div>
        <div v-for="file in team.files" :key="file.id" class="team__file mt-2">

          <a v-if="['pdf', 'pptx', 'ppt'].includes(file.type)"
             :href="'https://docs.google.com/gview?embedded=true&url=' + file.link"
             target="_blank">
            <v-icon color="primary" left size="12">mdi-link</v-icon>
            {{ file.filename }}
          </a>
          <a v-else :href="file.link" target="_blank">
            <v-icon color="primary" left size="12">mdi-link</v-icon>
            {{ file.filename }}
          </a>
        </div>
      </div>
      <div class="team-rubrics">
        <h4>Оценивание</h4>
        <div class="team-rubrics__item" v-for="rubric in event.rubrics" :key="rubric.id">
          <div class="team-rubrics__title">
            {{ rubric.title }},
            <div style="font-weight: 200">
            <span v-if="unsaved[team.id+'_'+rubric.id] !== undefined" :style="'color: red'">
              Не сохранено
            </span>
              {{ rubric.description }}

            </div>
          </div>
          <div class="team-rubrics__point">

            <InputUI :is-valid="unsaved[team.id+'_'+rubric.id] === undefined" inputmode="numeric" type="number"
                     @input="addToSave(team.id, rubric, $event)"
                     :value="unsaved[team.id+'_'+rubric.id] !== undefined ? unsaved[team.id+'_'+rubric.id] : points[team.id+'_'+rubric.id]"
                     :min="0" :max="rubric.points_max"
                     style="margin-bottom: 0; width: 4em" placeholder="0"/>

            <div class="team-rubrics__points_max">
              /
              {{ rubric.points_max }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-btn
        elevation="0"
        fab
        fixed
        bottom
        @click="goToNow"
        right
    >
      <v-icon>
        mdi-calendar
      </v-icon>
    </v-btn>
    <v-snackbar :color="snackbar.color"
                v-model="snackbar.show"
                :timeout="1000"
    >

      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            icon
            v-bind="attrs"
            @click="snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import InputUI from "@/components/UI/InputUI.vue";
import UserLinkComponent from "@/components/Profile/UserLinkComponent.vue";

export default {
  name: "Pointlist",
  components: {InputUI, UserLinkComponent},
  computed: {
    teams() {
      if (!this.search) {
        return this.$store.state.events.teams;
      }
      return this.$store.state.events.teams.filter((t) =>
          t.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 ||
          t.members.map(m => m.name).join(',').toLowerCase().indexOf(this.search.toLowerCase()) !== -1
      )
    },
    points: {
      get() {
        return this.$store.state.events.adminTeamPoints;
      },
      set() {
      }
    },
    event() {
      return this.$store.state.events.currentEvent;
    },
  },
  data() {
    return {
      unsaved: {},
      search: '',
      snackbar: {
        show: false,
        text: '',
        color: 'green',
        saveTimer: null
      }
    }
  },
  updated() {
    // eslint-disable-next-line no-console
    console.log('!r3ender')
  },
  methods: {
    sn(text, color = 'yellow') {
      this.snackbar = {text, show: true, color}
    },
    goToNow() {
      const teams = [...this.$store.state.events.teams];
      const sort_near_teams = teams.filter(t => t.slot_id > 0).map((t) => ({...t, diff: Math.abs(this.$moment.utc(t.slot.start_at).local().diff(this.$moment(), 'minutes'))}))
      const s = sort_near_teams.sort((a,b) => a.diff - b.diff);
      // eslint-disable-next-line no-console
      console.log(s);
      const team = (s[0] || null);
      if(team){
        document.getElementById('team_'+team.id).scrollIntoView({block: "center", behavior: "smooth"});
      }
    },
    addToSave(team_id, rubric, points) {
      let key = team_id + '_' + rubric.id;
      if (points !== '' && (parseInt(points) != points || parseInt(points) > rubric.points_max || parseInt(points) < 0)) {
        this.sn('Неправильное кол-во баллов, максимум - ' + rubric.points_max, 'red');
        let current = this.points[key];
        if (points > rubric.points_max) {
          points = rubric.points_max
        } else if (current === null) {
          points = 0;
        } else {
          points = null
        }
      }

      let copy = {...this.unsaved}
      copy[key] = parseInt(points)
      this.unsaved = copy
      clearInterval(this.saveTimer);
      this.saveTimer = setInterval(() => {
        this.save()
      }, 2000);
    },

    save() {
      if (!Object.keys(this.unsaved).length) return;
      this.$store.dispatch('updateAdminEventTeamPoints', {id: this.event.id, set: this.unsaved}).then(() => {
        this.sn('Сохранено', 'green');
        this.unsaved = {};
        clearInterval(this.saveTimer);
      }).catch(() => {
        this.sn('Не удалось сохранить. Попробуем через 5 секунд еще раз.', 'red');
      })
    }
  },
  beforeUpdate() {
    // eslint-disable-next-line no-console
    console.log('render list')
  },
  mounted() {
    this.$store.dispatch('getAdminEventTeamPoints', this.event.id).then(() => {
      this.sn('Можно выставлять', 'blue');
    })
  }
}
</script>

<style scoped>
.team {
  color: black;
  padding: 10px;
  border-bottom: 1px solid #efefef;
}

.team-title {
  font-weight: 500;
  font-size: 16px;
}

.team-rubrics__title {
  font-weight: 420;
  font-size: 14px;
  margin-right: 10px;
  min-width: 40%;
  max-width: 70vw;
}

.team-rubrics__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 10px;
}

.team-rubrics__point {
  display: flex;
  align-items: center;
  max-width: 30vw;
}

.team-rubrics__points_max {
  font-size: 12px;
  padding: 10px;
  white-space: pre;
  width: 3em;
}
</style>
