<template>
  <v-container>

    <div v-if="$store.state.events.eventWasLoaded && !loading">
      <h2>Оценочный лист {{ event.name }} </h2>
      <v-row class="mt-5">
        <v-col cols="12" md="6">
          <div class="widget mb-3">
            <h4>Эксперты мероприятия</h4> <br/>
            <UserLinkComponent class="mt-3 mr-1" v-for="expert in experts" :key="expert.id" :user="expert"/>
          </div>
          <v-dialog
              v-model="dialog"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  outlined
                  rounded
                  dark
                  v-bind="attrs"
                  v-on="on"
              >
                Открыть оценочный лист
              </v-btn>
            </template>
            <Pointlist @close="dialog = false"/>
            <!--            <div>-->
            <!--              <v-toolbar-->
            <!--                  dark-->
            <!--                  dense-->
            <!--                  color="primary"-->
            <!--              >-->
            <!--                <v-btn-->
            <!--                    icon-->
            <!--                    dark-->
            <!--                    @click="dialog = false"-->
            <!--                >-->
            <!--                  <v-icon>mdi-close</v-icon>-->
            <!--                </v-btn>-->
            <!--                <v-toolbar-title>Оценочный лист</v-toolbar-title>-->
            <!--                <v-spacer></v-spacer>-->
            <!--              </v-toolbar>-->
            <!--              <div class="teams_wrap">-->
            <!--                <table class="teams">-->
            <!--                  <thead>-->
            <!--                  <tr class="team">-->
            <!--                    <td style="width: 2em"></td>-->
            <!--                    <td style="width: 5em"></td>-->
            <!--                    <td style="width: 70px;"></td>-->
            <!--                    <td>Команда</td>-->
            <!--                    <td>Участники</td>-->
            <!--                    <td style="min-width: 200px">Файлы</td>-->
            <!--                    <td style="min-width: 200px">Кейс</td>-->
            <!--                    <td v-for="rubric in event.rubrics" :key="rubric.id" class="team__rubric">-->
            <!--                      <div style="font-size: 10px">-->
            <!--                        0 - {{ rubric.points_max }}-->
            <!--                      </div>-->
            <!--                      {{ rubric.title }}-->
            <!--                    </td>-->
            <!--                  </tr>-->
            <!--                  </thead>-->
            <!--                  <tr v-for="(team, index) in teams" :key="team.id" :class="{'team_hide': team.is_hide}" class="team">-->
            <!--                    <td>-->
            <!--                      {{ index + 1 }}-->
            <!--                    </td>-->
            <!--                    <td>-->
            <!--                      <div class="team__points">-->
            <!--                        {{ team.points }}-->
            <!--                      </div>-->
            <!--                      <v-btn v-if="event.can_admin" icon small @click="hideTeam(team)">-->
            <!--                        <v-icon v-if="team.is_hide" small>-->
            <!--                          mdi-eye-off-->
            <!--                        </v-icon>-->
            <!--                        <v-icon v-else small>mdi-eye</v-icon>-->
            <!--                      </v-btn>-->
            <!--                    </td>-->
            <!--                    <td>-->
            <!--                      <div class="team__logo">-->
            <!--                        <img :src="team.logo"/>-->
            <!--                      </div>-->
            <!--                    </td>-->
            <!--                    <td>-->
            <!--                      <h4>-->
            <!--                        {{ team.name }}-->
            <!--                      </h4>-->
            <!--                      <div>-->
            <!--                        {{ team.description }}-->
            <!--                      </div>-->
            <!--                      <div v-if="team.project_id > 0">-->
            <!--                        <a :href="'https://student.surgu.ru/#/projects/'+team.project_id" target="_blank">Ссылка на-->
            <!--                          проект</a>-->
            <!--                      </div>-->
            <!--                      <div v-if="team.repo_link">-->
            <!--                        <a :href="team.repo_link" target="_blank">Репозиторий</a>-->
            <!--                      </div>-->
            <!--                      <div v-if="team.prototype_link">-->
            <!--                        <a :href="team.prototype_link" target="_blank">Демо</a>-->
            <!--                      </div>-->
            <!--                    </td>-->
            <!--                    <td>-->
            <!--                      <UserLinkComponent v-for="member in team.members" :key="member.id" :user="member" class="mt-3"/>-->
            <!--                    </td>-->
            <!--                    <td>-->
            <!--                                  <div v-if="!team.files.length" class="text-center" style="font-size: 12px">Нет файлов</div>-->
            <!--                                  <div v-for="file in team.files" :key="file.id" class="team__file mt-2">-->

            <!--                                    <a v-if="['pdf', 'pptx', 'ppt'].includes(file.type)"-->
            <!--                                       :href="'https://docs.google.com/gview?embedded=true&url=' + file.link"-->
            <!--                                       target="_blank">-->
            <!--                                      <v-icon color="primary" left size="12">mdi-link</v-icon>-->
            <!--                                      {{ file.filename }}-->
            <!--                                    </a>-->
            <!--                                    <a v-else :href="file.link" target="_blank">-->
            <!--                                      <v-icon color="primary" left size="12">mdi-link</v-icon>-->
            <!--                                      {{ file.filename }}-->
            <!--                                    </a>-->
            <!--                                  </div>-->
            <!--                    </td>-->
            <!--                    <td>-->
            <!--                      <div v-if="team.case">-->
            <!--                        {{ team.case.title }}-->
            <!--                        <v-btn x-small :href="team.case.link" text>Открыть</v-btn>-->
            <!--                      </div>-->
            <!--                      <div v-else>-->
            <!--                        Не выбран-->
            <!--                      </div>-->

            <!--                    </td>-->
            <!--                    <td v-for="rubric in event.rubrics" :key="rubric.id" class="team__rubric">-->

            <!--                    </td>-->
            <!--                    <td style="width:100%;">&nbsp;</td>-->
            <!--                  </tr>-->
            <!--                </table>-->
            <!--              </div>-->
            <!--            </div>-->
          </v-dialog>
          <LeadersBoardWidget :teams="teams" class="mt-2 mb-3"/>
        </v-col>
        <v-col cols="12" md="6">
          <div class="widget mb-3">
            <h4>Оценивают сейчас</h4> <br/>
            <UserLinkComponent class="mt-3 mr-1" v-for="expert in onlineExperts" :key="expert.id" :user="expert"/>
          </div>
        </v-col>
      </v-row>


    </div>
    <v-skeleton-loader v-else
                       type="card-avatar, article, actions"
    ></v-skeleton-loader>
  </v-container>
</template>

<script>
import UserLinkComponent from "@/components/Profile/UserLinkComponent.vue";
import {mapGetters} from "vuex";
import teams from "@/api/teams.js";
import LeadersBoardWidget from "@/components/Events/EventParts/Widgets/LeadersBoardWidget.vue";
import Pointlist from "@/components/Events/EventParts/Pointlist.vue";

export default {
  name: "EventTeamPoints",
  components: {LeadersBoardWidget, UserLinkComponent, Pointlist},
  data() {
    return {
      loading: true,
      onlineExperts: [],
      loadings: {},
      dialog: false
    }
  },
  computed: {
    user() {
      return this.$store.state.user.currentUser;
    },
    event() {
      return this.$store.state.events.currentEvent;
    },
    teams() {
      return this.$store.state.events.teams;
    },
    experts() {
      return this.$store.state.events.experts;
    },
    ...mapGetters({
      getPoint: 'getEventTeamPointsByTeamIdAndAdminIDAndRubricID'
    })
  },
  updated() {
    // eslint-disable-next-line no-console
    console.log('render all')
  },
  methods: {
    hideTeam(team) {
      team.is_hide = !team.is_hide;
      teams.hide(team.id, team.is_hide).then(() => {
        this.$store.dispatch('getTeams', {event_id: this.event.id});
      })

    },
    getPointEarned(team_id, admin_id, rubric_id) {
      let result = this.getPoint(team_id, admin_id, rubric_id)[0];
      return !result ? null : result.points_earned;
    },
    updatePoint(admin_id, rubric_id, points_earned, team_id) {

      //const old_point = this.getPointEarned(team_id, admin_id, rubric_id);
      // this.$set(this.loadings, rubric_id + '_' + team_id + '_' + admin_id, true);
      this.$store.dispatch('updateEventTeamPoint', {
        team_id,
        admin_id,
        rubric_id,
        points_earned
      }).then(() => {
        //this.$set(this.loadings, rubric_id + '_' + team_id + '_' + admin_id, false);
      }).catch(() => {
        this.$store.dispatch('getEventTeamPoints', {id: this.event.id}).then(() => {
          this.$set(this.loadings, rubric_id + '_' + team_id + '_' + admin_id, false);
        });

      });
      //this.$store.dispatch('getTeams', {event_id: this.event.id});
    }
  },
  beforeUnmount() {

  },
  async mounted() {
    await this.$store.commit('setEventWasLoaded', false);
    await this.$store.dispatch('getEvent', {id: this.$route.params.id}).then(() => {
      Promise.all(
          [
            this.$store.dispatch('getTeams', {event_id: this.event.id}),
            this.$store.dispatch('getExperts', {id: this.event.id}),
            this.$store.dispatch('getEventTeamPoints', {id: this.event.id})]).then(() => {
        this.loading = false

        window.Echo.join('event_experts.' + this.event.id)
            .here((el) => {
              // eslint-disable-next-line no-console
              console.table(el);
              this.onlineExperts = el;
            })
            .joining((joiningMember) => {
              this.onlineExperts = [...this.onlineExperts, joiningMember];
            })
            .leaving((leavingMember) => {
              this.onlineExperts = this.onlineExperts.filter((e) => e.id !== leavingMember.id)
            }).listen('experts_updated', () => {
          this.$store.dispatch('getExperts', {id: this.event.id});
        });
      });
    });
    if (!this.event.can_set_points) {
      await this.$router.push('/auth')
    }
  }
}
</script>

<style scoped>
.teams {
  table-layout: fixed;
  border-collapse: collapse;
  min-width: 100%;
  border-radius: 10px;
}

.team {
  padding: 10px;
  margin-top: 10px;
}

.team__logo {
  width: 45px;
  height: 45px;
  overflow: hidden;
  border-radius: 10px;
}

.team__logo img {
  max-width: 100%;
}

.team__points {
  padding: 5px;
  background: #59abef;
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}


.team__rubric input {
  width: 3em;
  height: 3em;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #f6f6f6;
  background: #f6f6f6;
  text-align: center;
  margin-top: 10px;
}

.team__rubric input:focus {
  border: 1px solid #bfbfbf;
  outline: none;
}

.team__rubric {
  padding: 0px 15px;
}


.team td {
  padding: 10px;
}

.teams_wrap {
  overflow-y: scroll;
  background: #eaeaea;
  width: 100%;
  height: 100vh;
}

.team td {
  border-right: 1px solid #cfe3f1;
}

.team__rubric {
  text-align: center;
}

.team:nth-child(even) {
  background: #cfe3f1;
}

.team__file a {
  background: #d7d6d6;
  padding: 10px;
  border-radius: 10px;
  display: block;
  font-size: 10px;
  text-decoration: none;
  color: black;
}

.team__file a:hover {
  background: #d0d0d0;
}

.widget {
  padding: 20px;
  border-radius: 20px;
  background: #eaeaea;
}

.team_hide {
  opacity: 0.3;
}

.input_filled {
  border-color: #8bcb8b !important;
}

thead td {
  position: sticky;
  top: 0;
  background: #efefef;
  z-index: 1000;
}
</style>
